<template>
    <div id="wrap" >
        <guide :date = GuidePage @guideClick = "guideClick"></guide>
        <plate :date = formDate></plate>
        <banner :date = RotationChart></banner>
        <base-bottom></base-bottom>
    </div>
</template>
 
<script>
    import { ShowHomeInfo } from "../api/home.js";
    import baseBottom from '../components/baseBottom.vue';
    import plate from './components/plate.vue';
    import guide from './components/guide.vue';
    import banner from './components/banner.vue';
    export default {
        name: 'Home',
        data(){
            return{
                //轮播图
                RotationChart:{},
                //引导页
                GuidePage:{},
                formDate:{
                    //公司简介
                    BriefIntroduction:{},
                    //e葩钻
                    EPD:{},
                    //葩钻
                    PD:{},
                    //产品专利
                    Patent:{},
                },
            }
        },
        components:{
            baseBottom,
            plate,
            guide,
            banner
        },
        created(){
            this.getInfo()
        },
        methods:{
            getInfo(){
                ShowHomeInfo().then(res=>{
                    console.log(res.Data.GuidePage[0]);
                    this.formDate.BriefIntroduction = res.Data.BriefIntroduction[0]
                    this.formDate.EPD = res.Data.EPD[0]
                    this.formDate.PD = res.Data.PD[0]
                    this.formDate.Patent = res.Data.Patent[0]
                    this.GuidePage = res.Data.GuidePage[0]
                    this.RotationChart = res.Data.RotationChart[0]
                    let arr = this.RotationChart.PImage.split(',')
                    this.RotationChart.bannerList = arr.map(item=>{
                        return {PImage:item}
                    })
                })
            },
            guideClick(){
                window.scrollTo({
                'top': document.documentElement.clientHeight-120,
                'behavior': 'smooth'
                })
            },
        },
    }
</script>
<style  lang="scss">
    #wrap {
        overflow: hidden;
        width: 100%;
    }
    #wrap::-webkit-scrollbar { width: 0 !important }
    #wrap { -ms-overflow-style: none; }
    #wrap { overflow: -moz-scrollbars-none; }

</style>
