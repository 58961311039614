<template>
    <div class="banner">
        <div class="banner-title">
            <span>展厅实景</span>
            <div class="banner-title-dian"></div>
        </div>
        <div class="banner-title-fu">
            <span>Real Scene Of Exhibition Hal</span>
        </div>
        <div class="banner-main">
             <el-carousel :interval="5000" trigger="click">
                <el-carousel-item v-for="(item,index) in date.bannerList" :key="index">
                   <img :src="item.PImage" alt="" width="100%" height="100%">
                </el-carousel-item>
                <div class="banner-main-text">
                    <span>{{date.Maintitle}}</span> 
                </div>
            </el-carousel>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        date:{
            type: Object,
            default () {
                return {}
            }
        }
    },
    data() {
        return{}
    },
}
</script>
<style lang="scss" scoped>
    .banner{
        width: 1200px;
        margin: 0 auto;
        padding: 20px 0 0 0;
        &-title{
            font-size: 30px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #2A2A3E;
            text-align: center;
            position: relative;
        }
        &-main{
            width: 1200px;
            height: 580px;
            margin-top: 100px;
            .banner-main-text{
                width: 1200px;
                height: 108px;
                background: #85a5eb;
                opacity: 0.5885;
                position: absolute;
                left: 0;
                bottom: 0;
                text-align: center;
                line-height: 108px;
                font-size: 30px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                z-index: 80;
            }
            .el-carousel{
                margin: 0 auto;
                width: 1200px;
                height: 580px;
                position: relative;
                /deep/.el-carousel__container{
                    height: 580px;
                }
                /deep/.el-carousel__indicators--horizontal{
                    bottom: 10px;
                    z-index: 89;
                }
                /deep/.el-carousel__button{
                    width: 8px;
                    height: 8px;
                    background: #FFFFFF;
                    border-radius: 50%;
                    opacity: 1;
                }
                /deep/.el-carousel__indicator--horizontal{
                    padding: 0 !important;
                    margin: 0 6px;
                }
                /deep/.el-carousel__indicator--horizontal.is-active{
                    width: 40px;
                    height: 8px;
                    background: #0505AF;
                    border-radius: 4px;
                }
                /deep/.is-active .el-carousel__button{
                    width: 40px;
                    height: 8px;
                    background: #0505AF;
                    border-radius: 4px;
                }
            }
            
        }
        &-title-dian{
            position: absolute;
            width: 15px;
            height: 15px;
            background: linear-gradient(339deg, rgba(255,255,255,0.1) 0%, #0505AE 100%, #0505AE 100%);
            border-radius: 50%;
            left: 54%;
            top: 3px;
        }
        &-title-fu{
            text-align: center;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #84849F;
        }
    }
</style>
