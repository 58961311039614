<template>
    <div class="guide" :style="{height:screenHeight + 'px'}">
        <div class="guide-img" :style="{backgroundImage: 'url('+date.PImage+')'}">
            <div class="guide-main-title">
                {{date.Maintitle}}
            </div>
            <div class="guide-fu-title">
                {{date.Subtitle}}
            </div>
            <div class="guide-next">
                <img @click="goIndex()" src="../../assets/img/double.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props:{
            date:{
                type: Object,
                default () {
                    return {}
                }
            }
        },
        data(){
            return{
                screenHeight: document.documentElement.clientHeight-120,
            }
        },
        mounted(){},
        methods:{
            goIndex(i){
                this.$emit('guideClick',i)
            },
        },
    }
</script>
<style lang="scss" scoped>
.guide{
    background-image: url('../../assets/img/meigui.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    width: 1103px;
    margin: 0 auto;
    &-img{
        width: 568px;
        height: calc(100% - 75px);
        margin: 0 auto;
        padding-top: 75px;
        background-size: 80%;
        background-repeat: no-repeat;
        background-position:center center;
        position: relative;
    }
    &-main-title{
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 1200px;
        height: 90px;
        font-size: 60px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #0505AF;
        text-align: center;
    }
    &-fu-title{
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 1200px;
        height: 32px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #BBBBE7;
        text-align: center;
    }
    &-next{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            cursor: pointer;
        }
    }
}
</style>