<template>
    <div class="plate">
        <div class="plate-first">
            <div class="plate-company" @click="godetail('/company')" :style="{backgroundImage: 'url('+date.BriefIntroduction.PImage+')'}">
                <div class="plate-company-title">
                    {{date.BriefIntroduction.Maintitle}}
                </div>
                <div class="plate-company-text">
                    {{date.BriefIntroduction.Subtitle}}
                </div>
                <div class="plate-company-line"></div>
                <div class="plate-company-lineText">了解更多 ></div>
            </div>
            <div class="plate-product" @click="godetail('/patent')" :style="{backgroundImage: 'url('+date.Patent.PImage+')'}">
                <div class="plate-product-title">
                    {{date.Patent.Maintitle}}
                </div>
                <div class="plate-product-text">
                    {{date.Patent.Subtitle}}
                </div>
            </div>
        </div>
        <div class="plate-second">
            <div class="plate-App" @click="godetail('/application')" :style="{backgroundImage: 'url('+date.PD.PImage+')'}">
                <div class="plate-App-title">
                    {{date.PD.Maintitle}}
                </div>
                <div class="plate-App-text">
                    {{date.PD.Subtitle}}
                </div>
            </div>
            <div class="plate-epz" @click="godetail('/web')" :style="{backgroundImage: 'url('+date.EPD.PImage+')'}">
                <div class="plate-epz-title">
                    {{date.EPD.Maintitle}}
                </div>
                <div class="plate-epz-text">
                    {{date.EPD.Subtitle}}
                </div>
            </div>
        </div>
        <!-- <div class="plate-next">
            <img @click="goIndex" src="../../assets/img/double.png" alt="">
        </div> -->
        
    </div>
</template>
<script>
    export default {
        props:{
            date:{
                type: Object,
                default () {
                    return {}
                }
            }
        },
        data(){
            return{
                screenHeight: document.documentElement.clientHeight-120,
            }
        },
        methods:{
            goIndex(){
                this.$emit('plateClick')
            },
            godetail(path){
                this.$router.push(path)
            },
        },
    }
</script>
<style scoped lang="scss">
    .plate{
        width: 1200px;
        height: 710px;
        margin: 0 auto;
        padding: 50px 0;
        &-first{
            display: flex;
            justify-content: space-between;
        }
        &-second{
            display: flex;
            justify-content: space-between;
            margin-top: 25px;
        }
        &-next{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            img{
                cursor: pointer;
            }
        }
        &-company{
            width: 800px;
            height: 400px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            border-radius: 4px;
            transition: .5s;
            cursor: pointer;
        }
        &-company-title{
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            padding: 120px 0 0 60px;
        }
        &-company-text{
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            padding: 0 0 0 60px;
        }

        &-company-line{
            width: 80px;
            height: 2px;
            background: #FFFFFF;
            opacity: 0.8;
            margin: 40px 0 8px 60px;
        }
        &-company-lineText{
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            padding: 0 0 0 60px;
        }
        &-product{
            width: 376px;
            height: 400px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            border-radius: 4px;
            transition: .5s;
            cursor: pointer;
        }
        &-product-title{
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            padding: 56px 0 10px 105px;
        }
        &-product-text{
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            padding: 0 0 0 105px;
        }
        &-App{
            width: 540px;
            height: 250px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            border-radius: 4px;
            transition: .5s;
            cursor: pointer;
        }
        &-App-title{
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            padding: 60px 0 10px 60px;
        }
        &-App-text{
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            padding: 0 0 0 60px;
        }
        &-epz{
            width: 636px;
            height: 250px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            border-radius: 4px;
            transition: .5s;
            cursor: pointer;
        }
        &-epz-title{
            font-size: 40px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            padding: 60px 0 10px 60px;
        }
        &-epz-text{
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            padding: 0 0 0 60px;
        }
        &-company:hover,&-product:hover,&-App:hover,&-epz:hover{
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
            transition: .5s;
        }
    }
</style>